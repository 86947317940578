<template>
  <div>
    <!--begin::Roles-->
    <div v-if="selectedClient" class="row">
      <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
        <Roles></Roles>
      </div>
    </div>
    <div v-else class="row">
      <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
        <b-alert variant="danger" show>
          {{ $t("role.selectClient") }}
        </b-alert>
      </div>
    </div>
    <!--end::Roles-->
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import Roles from "@/components/Admins/Roles/Table";
import { mapGetters } from "vuex";

export default {
  name: "AdminsRolesIndex",
  components: {
    Roles
  },
  computed: {
    ...mapGetters(["selectedClient"])
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {
        title: this.$t("menu.projectSettings"),
        route: ""
      },
      {
        title: this.$t("menu.projectSettingsRoles"),
        route: ""
      },
      {
        title: this.$t("general.overview"),
        route: "/project/settings/roles"
      }
    ]);
  }
};
</script>
